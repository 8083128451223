@use '../../../scss/abstracts/' as *;

.city-modal {
  width: 586px;

  &-form {
    & > * + * {
      margin-top: 27px;
    }

    &__services {
      display: flex;
      align-items: center;
      column-gap: 14px;
    }
  }
}
