@use '../../../scss/abstracts/' as *;

.g-table {
  background-color: $white;
  border-radius: 4px;
  font-family: $inter;
  min-width: 300px;
  padding: 18px 16px;

  .btn-export {
    display: flex;
    align-items: center;
    height: 30px;
    margin-left: auto;
    // font-size: 10px;

    span {
      display: flex;
      margin-right: 4px;
    }
  }

  .table {
    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;
    }

    &-title {
      display: flex;
      align-items: center;
      height: 29px;

      h1 {
        margin-left: 10px;
        font-size: $font-medium;
      }
    }

    &-body {
      min-height: 200px;

      .btn-icon {
        cursor: pointer;
      }

      .pre-white-space {
        white-space: pre;
      }
    }

    &-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 18px;

      .link {
        color: $lightBlue;
        font-size: $font-small;
        font-weight: 500;
      }
    }
  }
}
