@use '../../../scss/abstracts/' as *;

.select-input {
  &-driver {
    position: relative;

    .input {
      &-title {
        display: block;
        font-size: $font-small;
        font-weight: 700;
        margin-bottom: 12px;
      }
    }
  }

  &-field {
    position: relative;
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: none;
    outline: 1px solid #e0e0e0;
    font-size: $font-small;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-weight: 500;

    .icon {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    &.focus {
      outline-color: #0085ff;
    }

    &.disabled {
      background-color: #efefef4d;
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
    }

    &__dropdown {
      margin-top: 6px;
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: auto;
      list-style-type: none;
      background-color: $white;
      border-radius: 5px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      z-index: 10;
    }

    &__item {
      padding: 12px 16px;
      font-size: $font-small;
      &:hover {
        background-color: $offWhite;
        cursor: pointer;
      }
    }
  }
}
