@use '../../../scss/abstracts/' as *;
.table {
  overflow-x: auto;
  margin-bottom: 14px;

  &-wrapper {
    width: 100%;
    border-spacing: 0;
    font-family: $inter;
    font-size: $font-small;
    color: $darkGray;
    border-radius: 3px;
  }

  &-head {
    font-weight: 800;
    background-color: #f7f7f7;
    border: 1px solid black;
    height: 43px;

    th {
      border-right: 1px solid #e0e0e0;

      &:last-child {
        border: none;
      }

      .sorted-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4px;
        cursor: pointer;

        div.chevrons {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &-body {
    background-color: $white;
    text-align: center;
    font-weight: 600;
    margin-bottom: 12px;

    td {
      border-right: 1px solid #e0e0e0;
      padding: 0 4px;

      &:last-child {
        border: none;
      }

      .schedule-price-vehicles {
        // height: 55px;
        min-height: 55px;
        width: 240px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        column-gap: 6px;
        row-gap: 8px;
        padding: 12px 0 12px;

        &__list {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 6px 16px;
          background-color: #f2f2f2;
          border-radius: 100px;

          span:nth-child(2) {
            padding: 2px 7px;
            background-color: $lightBlue;
            color: #fff;
            font-size: 10px;
            border-radius: 100%;
          }
        }
      }

      .schedule-price-locations {
        display: flex;
        justify-content: center;
        gap: 6px;

        div {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 6px 16px;
          background-color: #f2f2f2;
          border-radius: 100px;
        }
      }

      .promo-vehicles {
        // height: 55px;
        min-height: 55px;
        width: 240px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        column-gap: 6px;
        row-gap: 8px;
        padding: 12px 0 12px;

        &__list {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 6px 16px;
          background-color: #f2f2f2;
          border-radius: 100px;

          span:nth-child(2) {
            padding: 2px 7px;
            background-color: $lightBlue;
            color: #fff;
            font-size: 10px;
            border-radius: 100%;
          }
        }
      }

      .rental-duration-vehicles {
        // height: 55px;
        min-height: 55px;
        min-width: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        column-gap: 6px;
        row-gap: 8px;
        padding: 12px 0 12px;

        &__list {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 6px 16px;
          background-color: #f2f2f2;
          border-radius: 100px;

          span:nth-child(2) {
            padding: 2px 7px;
            background-color: $lightBlue;
            color: #fff;
            font-size: 10px;
            border-radius: 100%;
          }
        }
      }
    }

    [data-type='additional'] {
      padding: 4px 0;
      line-height: 1.25;
    }

    [data-type='no'] {
      width: 45px;
    }

    [data-type='brand_name'] {
      text-transform: capitalize;
    }

    [data-type='list_zone'] {
      width: 376px;
      padding: 4px 0;
    }

    & > tr {
      height: 56px;
      font-weight: 500;

      &:nth-child(even) {
        background-color: #f7f7f7;
      }

      .link-photo {
        color: $lightBlue;
      }
    }

    .btn-status {
      max-width: 106px;
      width: 100%;
      margin: 0 auto;
      border-radius: 5px;
      padding: 5px 0;
      font-weight: 700;
    }

    .btn-action {
      width: 200px;
      height: inherit;

      img {
        cursor: pointer;
        display: inline-block;
        margin: 0 3px;
      }
    }

    .active {
      background-color: #c5ffc7;
      color: #007a05;
      border-radius: 100px;
    }

    .not-active {
      background-color: #ffc5c5;
      color: #ba0000;
      border-radius: 100px;
    }

    .warning {
      color: #cf7c00;
      border-radius: 100px;
      background-color: #ffe5bf;
    }

    .col-highlight-danger {
      background-color: #ffb5b2;
    }

    .status-checkout,
    .status-reconfirmation,
    .status-paid,
    .status-half,
    .status-processed {
      font-weight: 400;
      background-color: #eeeeee;
      border-radius: 100px;
    }

    .status-completed,
    .status-full {
      font-weight: 400;
      background-color: #dbffde;
      color: #299b0a;
      border-radius: 100px;
    }

    .status-finished {
      font-weight: 400;
      background-color: #e7f3ff;
      color: #1c3d5d;
      border-radius: 100px;
    }

    .status-cancelled {
      font-weight: 400;
      background-color: #ffe4e4;
      color: #ff0000;
      border-radius: 100px;
    }

    .status-indebt,
    .status-approval {
      font-weight: 400;
      background-color: #fff5e7;
      color: #f1a33a;
      border-radius: 100px;
    }

    .status-default {
      background-color: transparent;
    }
  }
}
