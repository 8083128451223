@use '../../../scss/abstracts/' as *;

.tabs-container {
  .tab-list {
    // border-bottom: 1px solid $white;
    padding-left: 0;
    position: relative;
    // margin-bottom: 30px;

    &-item {
      display: inline-block;
      list-style: none;
      margin-bottom: -1px;
      padding: 0.5rem 0.75rem;
      cursor: pointer;
      padding: 15px 40px;
      border-radius: 5px 5px 0 0;
      margin-right: 8px;
      background-color: $white;

      // &:last-child {
      //   position: absolute;
      //   right: 0;
      //   margin-right: 0px;
      // }

      .tab-label {
        font-size: 14px;
        line-height: 17px;
        color: #757575;

        &.active {
          color: $white;
        }

        &.bordered {
          color: $lightBlue;
        }
      }
    }

    &-active {
      background-color: $lightBlue;
      border-width: 1px 1px 0 1px;
      font-weight: 700;
    }

    &-bordered {
      border: 1px solid $lightBlue;
      color: $lightBlue;
    }

    &-end-list {
      position: absolute;
      right: 0;
      margin-right: 0px;
    }

    .tab-gap {
      display: flex;
      gap: 10px;
    }
  }
}
