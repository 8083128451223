@use '../../../../../scss/abstracts/' as *;
@forward './WithoutDriverForm/without-driver-form.scss';
@forward './WithDriverForm/with-driver-form.scss';
@forward './AirportTransferForm/airport-transfer-form.scss';

.detail-sewa {
  margin-top: 32px;

  &__detail-sewa-form {
    display: flex;
    flex-direction: column;

    &__title {
      margin-bottom: 22px;
      text-align: left;
      width: 100%;

      h2 {
        color: #009ef7;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}
