@use '../../../scss/abstracts/' as *;

.select-field-car {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__input-field {
    width: 100%;
    padding: 0 12px;
    min-height: 44px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;
    overflow-x: hidden;

    &__input {
      width: 100%;
      min-height: 44px;
      border: none;
      outline: none;
      background: transparent;
      font-size: $font-small;

      &::placeholder {
        font-size: 12px;
      }
    }

    &__car {
      width: 100%;
      min-height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      pointer-events: none;

      div:nth-child(1) {
        display: flex;
        align-items: center;
        gap: 20px;

        div:nth-child(1) {
          width: 30px;
          height: 30px;
          background-color: #e0e0e0;
          border-radius: 3px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        div:nth-child(2) {
          height: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h3 {
            font-size: 10px;
            font-weight: bold;
            text-transform: capitalize;
          }

          span:nth-child(2) {
            display: flex;
            align-items: center;
            gap: 12px;

            p {
              font-size: 10px;
              font-weight: normal;
              text-transform: capitalize;
            }

            span {
              display: block;
              width: 4px;
              height: 4px;
              border-radius: 100%;
              background: #000;
            }
          }
        }
      }

      span:nth-child(2) {
        font-size: $font-small;
        font-weight: bold;
        color: #1c3d5d;
        justify-self: flex-end;
        margin-right: 5px;
      }
    }

    &__car-checkbox {
      width: 100%;
      min-height: 44px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      pointer-events: none;
      padding: 8px 0;

      span.name {
        height: 28px;
        background-color: #f2f2f2;
        display: flex;
        justify-content: center;
        gap: 6px;
        align-items: center;
        border-radius: 100px;
        padding: 0 20px;
        font-size: 10px;

        span {
          width: 15px;
          height: 15px;
          background-color: #009ef7;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          font-size: 12px;
        }
      }
    }
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 84px;
    max-height: 365px;
    border-radius: 9px;
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
    list-style: none;
    overflow-y: auto;
    z-index: 10;

    li:nth-child(1) {
      border-top: none;
    }

    li {
      height: 89px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 14px 21px;
      gap: 21px;
      border-top: 1px solid #e0e0e0;
      background-color: white;
      cursor: pointer;

      div:nth-child(1) {
        width: 61px;
        height: 61px;
        background-color: #e0e0e0;
        border-radius: 3px;
        overflow: hidden;
        pointer-events: none;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      div:nth-child(2) {
        height: 61px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        pointer-events: none;

        h3 {
          font-size: $font-small;
          font-weight: bold;
          text-transform: capitalize;
        }

        span:nth-child(2) {
          display: flex;
          align-items: center;
          gap: 12px;

          p {
            font-size: $font-small;
            font-weight: normal;
            text-transform: capitalize;
          }

          span {
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background: #000;
          }
        }

        span:nth-child(3) {
          font-size: $font-small;
          font-weight: bold;
          color: #1c3d5d;
        }
      }
    }

    li.list-car:hover {
      filter: brightness(95%);
      cursor: pointer;
    }

    li.list-car.disable {
      filter: brightness(95%);
      pointer-events: none;
    }

    li.list-check {
      cursor: default;

      div:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 12px;
      }

      div:last-child {
        margin-left: auto;
        width: 18px;
        height: 18px;
        background: #fff;
        border: 1px solid #d9d9d9;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        input[type='checkbox'] {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          cursor: pointer;
          opacity: 0;
        }
      }

      div:last-child.check {
        background: #0085ff;
      }
    }
  }
}
