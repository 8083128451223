@use '../../../scss/abstracts/' as *;

.navbar {
  width: 100%;
  background-color: $white;
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);

  &-active {
    font-size: 18px;
    margin-bottom: 6px;
  }

  &-date {
    font-size: $font-small;
    color: #9e9e9e;
  }

  &-right {
    display: flex;
    align-items: center;

    .btn {
      &-profile {
        border-radius: 6.14px;
        width: 30px;
        height: 30px;
        background-color: $lightBlue;
      }
    }

    figure {
      cursor: pointer;
      margin-left: 28px;
    }
  }
}
