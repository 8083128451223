@use '../../../scss/abstracts/' as *;

.g-input {
  .input-text-area {
    &-title {
      display: block;
      font-size: $font-small;
      font-weight: 700;
      margin-bottom: 12px;
    }

    &-field {
      position: relative;
      min-height: 44px;
      border-radius: 4px;
      border: none;
      outline: 1px solid #e0e0e0;
      font-size: $font-small;
      display: flex;
      align-items: center;
      font-weight: 500;
      width: 100%;
      resize: none;
      padding: 9px 12px;

      &::placeholder {
        font-size: 12px;
      }

      &:focus {
        outline-color: #0085ff;
      }
    }
  }

  .field-wrapper {
    position: relative;
  }
}
