@use '../../../scss/abstracts/' as *;

.input-telp-field {
  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__field {
    display: flex;
    gap: 12px;
    align-items: center;

    &__telp-country-code {
      position: relative;
      background-color: white;
      height: 44px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0 10px;
      border-radius: 5px;
      border: 1px solid #e0e0e0;
      gap: 12px;

      &__image {
        width: 25px;
        height: 14px;
      }

      span {
        font-size: $font-small;
      }

      &__dropdown-code {
        position: absolute;
        left: 0;
        right: 0;
        top: 50px;
        max-height: 176px;
        border-radius: 9px;
        background-color: white;
        box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
        list-style: none;
        overflow-y: auto;
        z-index: 10;

        li:nth-child(1) {
          border-top: none;
        }

        li {
          height: 44px;
          width: 100%;
          display: flex;
          align-items: center;
          padding: 11px 14px;
          gap: 6px;
          border-top: 1px solid #e0e0e0;
          background-color: white;
          cursor: pointer;
          box-sizing: border-box;

          img {
            width: 24px;
            height: 13px;
          }

          span {
            display: flex;
            align-items: center;
            gap: 2px;
            font-size: 0.6rem;
          }
        }

        li:hover {
          background-color: #e0e0e0;
        }

        .choosed {
          background-color: #e0e0e0;
        }
      }
    }

    &__telp-number {
      width: 80%;
      border: none;
      outline: none;
      padding: 0 12px;
      height: 44px;
      border-radius: 5px;
      outline: 1px solid #e0e0e0;
      font-size: $font-small;

      &::placeholder {
        font-size: 12px;
      }

      &:focus {
        outline-color: #0085ff;
      }
    }

    .disable {
      pointer-events: none;
      background-color: #f5f5f5;
    }
  }
}
