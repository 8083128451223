@use '../../../scss/abstracts/' as *;

.select-field-banks {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__input {
    width: 100%;
    border: none;
    outline: none;
    padding: 0 12px;
    height: 44px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;
    font-size: $font-small;
    background-color: #fff;

    &::placeholder {
      font-size: 12px;
    }

    &:focus {
      outline-color: #0085ff;
    }
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 84px;
    max-height: 176px;
    border-radius: 9px;
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
    list-style: none;
    overflow-y: auto;
    z-index: 10;

    li:nth-child(1) {
      border-top: none;
    }

    li {
      height: 44px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 11px 14px;
      gap: 14px;
      border-top: 1px solid #e0e0e0;
      background-color: white;
      cursor: pointer;

      div {
        width: 38px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e0e0e0;
        border-radius: 3px;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        color: black;
      }
    }

    li:hover {
      filter: brightness(95%);
    }
  }
}
