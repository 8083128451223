@use '../../../../../../scss/abstracts/' as *;

.airport-transfer-form {
  display: flex;
  flex-direction: column;

  &__rental-location {
    margin-bottom: 35px;
  }

  &__form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    row-gap: 38px;
    column-gap: 28px;
    margin-bottom: 50px;

    &__shuttle {
      position: relative;
      grid-column: 1/3;
      display: flex;
      justify-content: space-between;
      gap: 28px;

      div {
        width: 100%;
      }

      .switch {
        position: absolute;
        bottom: 7px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
      }
    }

    &__baggage-passenger {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
    }
  }

  &__additional-item {
    width: 100%;
    margin-bottom: 36px;

    h2 {
      color: #009ef7;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 22px;
    }

    div.wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 22px;

      .input-text-area-title {
        margin-bottom: 4px;
      }
    }
  }

  &__button-action {
    margin: 0 0 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    column-gap: 28px;

    &__action {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
}
