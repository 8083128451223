@forward './PromoList/DetailPromoList/detail-promo-list.scss';
@forward './PromoDetailComponent/index';

.promo {
  position: relative;

  .message {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
