@use '../../../scss/abstracts/' as *;

.upload-image {
  &__container {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 20px 18px;
    border-radius: 9px;
  }

  &__button {
    border: 1px dashed #cbcbcb;
    border-radius: 9px;
    font-size: $font-small;
    text-align: center;
    padding: 50px 0;

    p {
      color: #757575;

      span {
        color: #0085ff;
        font-weight: 700;
        cursor: pointer;
      }
    }

    &-sm {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 18px;
        height: 16px;
      }
      p {
        color: #757575;

        span {
          color: #0085ff;
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
  }

  &__thumbnails {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    text-align: center;

    & > *:nth-child(odd) {
      border-right: 1px solid #cbcbcb;
    }

    .thumbnail {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 10px 0;

      .image {
        width: 29px;
        height: 29px;
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          display: block;
        }
      }

      .filename {
        font-weight: 500;
        font-size: $font-small;
        margin-bottom: 0;
      }

      .icon {
        cursor: pointer;
        svg {
          display: block;
        }
      }
    }
  }
}
