// @use '../../../../scss/abstracts/' as *;
@use '../../../scss/abstracts/' as *;

.filter-transaction {
  width: 210px;
  position: relative;

  &-button {
    font-size: $font-small;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    padding: 6px 28px 6px 12px;

    &:hover {
      cursor: pointer;
    }
  }

  &-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  &-modal {
    position: absolute;
    right: 0;
    background: $white;
    width: 100%;
    margin-top: 10px;
    z-index: $modal;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding-left: 8px;
    padding-right: 8px;

    .line {
      border: 0.5px solid #ececec;
    }

    .active {
      font-weight: 700;
      color: $lightBlue;
    }

    .not-active {
      color: $black;
    }

    .btn {
      background-color: $white;
    }
  }

  &-list {
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 18px;
    gap: 4px;

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    scrollbar-width: auto;
    scrollbar-color: #292929 #ffffff;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: auto;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a8a8a8;
      border-radius: 10px;
      border: 3px solid #ffffff;
    }

    &-item {
      background-color: #f2f2f2;
      border-radius: 100px;
      padding: 1.5px 8px;
      font-size: 10px;
      min-width: fit-content;
      display: flex;
      align-items: center;

      .remove-icon {
        margin-left: 4px;
        opacity: 0.7;
        transition: 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &-item {
    padding: 8px;
    display: flex;
    align-items: center;
    font-size: $font-small;
    border-bottom: 0.5px solid #ececec;

    &:last-child {
      border: none;
    }

    label {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &-checkbox {
    margin-right: 8px;
    &:hover {
      cursor: pointer;
    }
  }
}
