@use '../../../scss/abstracts/' as *;

.text-area-editor {
  width: 100%;

  .ql-toolbar {
    border-bottom: none !important;
    border-radius: 8px 8px 0 0;
    border-color: #dbdbdb;
  }

  .ql-container {
    border-radius: 0 0 8px 8px;
    border-top: none !important;
  }

  .ql-editor strong {
    font-weight: bold;
  }

  .ql-editor em {
    font-style: italic;
  }

  &__text-area {
    height: 374px;
  }
}
